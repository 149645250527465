import React, { useEffect, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CustomSelect from 'components/CustomSelect'
import CustomCheckbox from 'components/CustomCheckbox'
import TimePicker from 'components/TimePicker'
import DatePicker from 'components/DatePicker'
import Form from 'components/Form'
import TimeModel from 'models/Time'
import defaults from './defaults'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import useStartEndDate from 'hooks/useStartEndDate'
import { types } from 'constants/times'
import weekdays from 'constants/weekdays'
import CustomAutocomplete from 'components/CustomAutocomplete'
import { formatDate } from 'utils/formatDateTime'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
  }) => {
    const hookForm = useForm({ resolver: yupResolver(TimeModel.validationSchema), defaultValues })
    const {
      control,
      formState: { errors },
      reset,
      watch,
    } = hookForm
    const watchStartEndDate = watch(['startDate', 'endDate'])
    const { minEndDate, maxStartDate } = useStartEndDate(watchStartEndDate)

    useEffect(() => {
      reset(defaultValues)
    }, [defaultValues])

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="isPrivate"
              render={({ field }) => (
                <CustomCheckbox
                  {...field}
                  InputLabelProps={{
                    label: 'Is Private?',
                    labelPlacement: 'start',
                    style: { marginLeft: 0 },
                  }}
                  error={errors.isPrivate}
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="type"
              render={({ field }) => (
                <CustomSelect {...field} label="Type" options={types} error={errors.type} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <CustomInput {...field} label="Description" error={errors.description} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="Start Date"
                  inputProps={{ max: formatDate(maxStartDate) }}
                  error={errors.startDate}
                  fullWidth
                />
              )}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <DatePicker
                  {...field}
                  label="End Date"
                  inputProps={{ min: formatDate(minEndDate) }}
                  error={errors.endDate}
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="startTime"
              render={({ field }) => <TimePicker {...field} label="Start Time" error={errors.startTime} fullWidth />}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Controller
              control={control}
              name="endTime"
              render={({ field }) => <TimePicker {...field} label="End Time" error={errors.endTime} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3}>
            <Controller
              control={control}
              name="interval"
              render={({ field }) => (
                <CustomInput
                  {...field}
                  type="number"
                  label="Time Interval(s)"
                  error={errors.interval}
                  helperText="every minute(s)"
                  fullWidth
                />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="days"
              render={({ field }) => (
                <CustomAutocomplete {...field} label="Days" options={weekdays} error={errors.days} fullWidth multiple />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
    )
  }
)
