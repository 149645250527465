import React, { useEffect, useState, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import { useForm, Controller } from 'react-hook-form'
import CustomCheckbox from 'components/CustomCheckbox'
import CustomInput from 'components/CustomInput'
import Form from 'components/Form'
import defaultValues from './defaults'
import SettingModel from 'models/Setting'
import { yupResolver } from '@hookform/resolvers/yup'
import useStyles from './styles'
import ColorSelector from './ColorSelector'
import BookingDays from './BookingDays'
import AutoConfirmCancel from './AutoConfirmCancel'
import SameDayBookings from './SameDayBookings'
import Notifications from './Notifications'
import ApproxTime from './ApproxTime'

export default memo(({ buttonText, settings = null, templates = {}, onSubmit }) => {
  const hookForm = useForm({
    resolver: yupResolver(SettingModel.validationSchema),
    defaultValues,
  })
  const {
    reset,
    control,
    formState: { errors },
  } = hookForm
  const classes = useStyles()

  useEffect(() => {
    reset(settings)
  }, [settings])

  const onDiscard = () => {
    reset(settings)
  }

  return (
    <Form
      title="Edit Settings"
      submitButtonText={buttonText}
      hookForm={hookForm}
      onSubmit={onSubmit}
      onDiscard={onDiscard}
      defaultValues={settings}
    >
      <ColorSelector control={control} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Controller
            control={control}
            name="welcome"
            render={({ field }) => <CustomInput {...field} label="Welcome Message" fullWidth />}
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Controller
            control={control}
            name="paymentMessage"
            render={({ field }) => <CustomInput {...field} label="Payment Message" fullWidth />}
          />
        </GridItem>
      </GridContainer>
      <BookingDays control={control} errors={errors} />
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <Controller
            control={control}
            name="hideBookedSlots"
            render={({ field }) => (
              <CustomCheckbox
                {...field}
                label="Description"
                InputLabelProps={{
                  label: 'Hide Booked Slots',
                  labelPlacement: 'start',
                  className: classes.checkbox,
                }}
              />
            )}
          />
        </GridItem>
      </GridContainer>
      <ApproxTime classes={classes} hookForm={hookForm} />
      <AutoConfirmCancel classes={classes} hookForm={hookForm} />
      <SameDayBookings classes={classes} hookForm={hookForm} />
      <Notifications classes={classes} hookForm={hookForm} templates={templates} />
    </Form>
  )
})
