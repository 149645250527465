import React, { useEffect, memo } from 'react'
import CustomAutocomplete from 'components/CustomAutocomplete'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import Form from 'components/Form'
import UserModel from 'models/User'
import defaults from './defaults'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
    roles = [],
    slots = [],
    assignSlot,
  }) => {
    const hookForm = useForm({ resolver: yupResolver(UserModel.validationSchema), defaultValues })
    const {
      control,
      formState: { errors },
    } = hookForm

    useEffect(() => {
      hookForm.reset(defaultValues)
    }, [defaultValues])

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        defaultValues={defaultValues}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => <CustomInput {...field} label="Email" error={errors.email} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <CustomInput {...field} label="Description" error={errors.description} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="roles"
              render={({ field }) => (
                <CustomAutocomplete
                  {...field}
                  label="Roles"
                  options={roles}
                  error={errors.roles}
                  optionValue="id"
                  optionLabel="name"
                  fullWidth
                  multiple
                />
              )}
            />
          </GridItem>
        </GridContainer>
        {assignSlot && (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Controller
                control={control}
                name="slots"
                render={({ field }) => (
                  <CustomAutocomplete
                    {...field}
                    label="Slots"
                    options={slots}
                    error={errors.slots}
                    optionValue="id"
                    optionLabel="name"
                    fullWidth
                    multiple
                  />
                )}
              />
            </GridItem>
          </GridContainer>
        )}
      </Form>
    )
  }
)
