import React, { useEffect, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CheckboxList from 'components/CheckboxList'
import Form from 'components/Form'
import RoleModel from 'models/Role'
import defaults from './defaults'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useAuth } from 'hooks/useAuth'
import { groupPermissions } from 'constants/permissions'

export default memo(
  ({
    buttonText,
    discardButtonText,
    title,
    subTitle,
    onSubmit,
    onDiscard,
    defaultValues = defaults,
    dirtyOnStart = true,
  }) => {
    const hookForm = useForm({ resolver: yupResolver(RoleModel.validationSchema), defaultValues })
    const {
      control,
      reset,
      formState: { errors },
    } = hookForm
    const [, , idToken] = useAuth()

    useEffect(() => {
      reset(defaultValues)
    }, [defaultValues])

    const filteredPermissions = groupPermissions.filter(
      (permission) => idToken.claims.permissions.filter((p) => permission.value.includes(p)).length > 0
    )

    return (
      <Form
        title={title}
        subTitle={subTitle}
        dirtyOnStart={dirtyOnStart}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        defaultValues={defaultValues}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <CustomInput {...field} label="Description" error={errors.description} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="permissions"
              render={({ field }) => (
                <CheckboxList {...field} label="Permissions" lists={filteredPermissions} error={errors.permissions} />
              )}
            />
          </GridItem>
        </GridContainer>
      </Form>
    )
  }
)
