import React, { useEffect, useState, memo } from 'react'
import GridItem from 'components/Grid/GridItem.js'
import GridContainer from 'components/Grid/GridContainer.js'
import CustomInput from 'components/CustomInput'
import CustomButton from 'components/CustomButtons/Button'
import Form from 'components/Form'
import ChangePasswordDialog from './ChangePasswordDialog'

import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { useAuth } from 'hooks/useAuth'
import { useSnackbar } from 'notistack'

import UserModel from 'models/User'
import defaultValues from './defaults'

import { getUser, updateProfile } from 'actions/user'
import { changePassword } from 'actions/login'
import useApi from 'hooks/useApi'

export default memo(({ buttonText, discardButtonText }) => {
  const { enqueueSnackbar } = useSnackbar()
  const hookForm = useForm({ resolver: yupResolver(UserModel.profileValidationSchema), defaultValues })
  const [openChangePassword, setOpenChangePassword] = useState(false)
  const {
    control,
    formState: { errors },
    reset,
  } = hookForm
  const [, auth] = useAuth()
  const [user] = useApi(() => Promise.all([getUser(auth?.uid)]), [auth?.uid])

  useEffect(() => {
    if (user) {
      reset(user)
    }
  }, [user])

  const onDiscard = () => {
    reset(user)
  }

  const onSubmit = async (data) => {
    try {
      await updateProfile(auth?.uid, user.set(data))
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  const onOpenChangePassword = () => {
    setOpenChangePassword(true)
  }

  const onCloseChangePassword = () => {
    setOpenChangePassword(false)
  }

  const onChangePassword = async ({ currentPassword, newPassword }) => {
    try {
      await changePassword(currentPassword, newPassword)
      enqueueSnackbar('Password changed successfully!', { variant: 'success' })
      onCloseChangePassword()
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  return (
    <>
      <Form
        title="Edit Profile"
        dirtyOnStart={false}
        submitButtonText={buttonText}
        discardButtonText={discardButtonText}
        hookForm={hookForm}
        onSubmit={onSubmit}
        onDiscard={onDiscard}
        defaultValues={user}
      >
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="name"
              render={({ field }) => <CustomInput {...field} label="Name" error={errors.name} fullWidth />}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <CustomInput {...field} label="Email" error={errors.email} InputProps={{ readOnly: true }} fullWidth />
              )}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <CustomButton onClick={onOpenChangePassword}>Change Password</CustomButton>
          </GridItem>
        </GridContainer>
      </Form>
      <ChangePasswordDialog
        open={openChangePassword}
        onClose={onCloseChangePassword}
        onChangePassword={onChangePassword}
      />
    </>
  )
})
